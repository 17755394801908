import React from "react";
import {
  Button,
  ConfirmationBox,
  HeadingGroup,
  Image,
  ModalLayout,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import useDocumentDetails from "./useDocumentDetails";
import OverviewShimmer from "../../../Global/Shimmers/OverviewShimmer";
import Style from "./documentDetails.module.scss";
import Assets from "../../../../assets/Assets";
import { PdfCard } from "../../../Global/PdfCard";
import { ImageCard } from "../../../Global/ImageCard";
import EmptyData from "../../../Global/EmptyData";
import UploadForm from "./UploadForm";

const DocumentDetails = () => {
  const {
    isFetching,
    DocumentData,
    showImageModal,
    modalType,
    imageData,
    showPdfModal,
    itemRejected,
    documentStatus,
    rejectReason,
    note,
    docId,
    approveAll,
    rejectAll,
    imageApprove,
    imageReject,
    pdfApprove,
    pdfReject,
    uploadModal,
    multipleViewer,
    lrsData,
    refetch,
    handleCloseUploadModal,
    handleUploadClick,
    setImageApprove,
    setImageReject,
    setPdfApprove,
    setPdfReject,
    handleYesAction,
    handleYesSeperateDocumentAction,
    handleCloseDocumentConfirmationModal,
    handleCloseConfirmationModal,
    handleApproveAllClick,
    handleRejectAllClick,
    handleVerification,
    setNote,
    setItemRejected,
    closePdfModal,
    closeImageModal,
    handleViewImage,
  } = useDocumentDetails();

  return (
    <div className="pro-pt-3">
      <div className="row">
        <div className="col">
          <HeadingGroup
            title={"Document Details"}
            className={`col-4 pro-mb-4`}
            buttonTitle={"Upload"}
            handleClick={handleUploadClick}
            propStyle={{ root_child: "" }}
          >
            {DocumentData?.current_status === "approved" ? (
              <span className="pro-badge badge-primary-outline pro-px-4 pro-py-1">
                Approved
              </span>
            ) : DocumentData?.current_status === "rejected" ? (
              <div className="pro-d-flex pro-gap-2">
                <span className="pro-badge badge-danger-outline pro-px-4 pro-py-1">
                  Rejected
                </span>
              </div>
            ) : (
              ""
            )}
          </HeadingGroup>
        </div>

        {DocumentData?.data && (
          <div className="col-auto">
            <div className="pro-d-flex pro-ms-auto">
              {DocumentData?.current_status === "pending" ? (
                <>
                  <Button
                    className={`pro-btn-outline lg pro-px-5`}
                    onClick={() => handleRejectAllClick()}
                  >
                    Reject All
                  </Button>
                  <Button
                    className={`pro-btn-primary pro-ms-3 lg pro-px-5`}
                    onClick={() => handleApproveAllClick()}
                  >
                    Approve All
                  </Button>
                </>
              ) : DocumentData?.current_status === "approved" ? (
                <>
                  <Button
                    className={`pro-btn-outline lg pro-px-5`}
                    onClick={() => handleRejectAllClick()}
                  >
                    Reject All
                  </Button>
                </>
              ) : (
                <div className="pro-d-flex pro-gap-2">
                  <Button
                    className={`pro-btn-primary pro-ms-3 lg pro-px-5`}
                    onClick={() => handleApproveAllClick()}
                  >
                    Approve All
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : !DocumentData?.data ? (
        <EmptyData />
      ) : (
        <div className={`pro-w-100 pro-pb-6`}>
          {DocumentData?.data?.map((item) => (
            <div key={item?.id}>
              {" "}
              {item?.documents?.length !== 0 && (
                <h6 className="pro-ttl h6 pro-mb-4 pro-mt-5">{item?.title}</h6>
              )}
              <div className="pro-d-flex pro-flex-wrap pro-gap-4">
                {item.documents?.map((documentItem) => (
                  <div className={`${Style.root}`}>
                    <div className={`${``}`} key={documentItem?.document_id}>
                      <div className={`${Style.root_inner}`}>
                        <div
                          className={`${Style.root_image}`}
                          onClick={(e) => handleViewImage(documentItem, item)}
                        >
                          <Image
                            src={
                              documentItem?.file_type === "PDF document"
                                ? Assets?.PDF_LOGO
                                : documentItem?.url
                            }
                            alt={documentItem?.file_name}
                            width={146}
                            height={123}
                          />
                        </div>
                        <div
                          className={`${Style.badge} pro-ps-1 pro-bg-light pro-rounded-3 pro-d-flex pro-items-center`}
                        >
                          <span className={`${Style.badge_text} pro-fw-medium`}>
                            {documentItem?.document_from !== "AI"
                              ? documentItem?.document_status
                              : documentItem?.document_status === "Pending"
                              ? "AI"
                              : "AI " + documentItem?.document_status}
                          </span>
                          <div
                            className={`pro-check-box rounded-tick ${Style.round_tick}`}
                          >
                            <img
                              alt="badge"
                              src={`
                                    ${
                                      documentItem?.document_status ===
                                      "Pending"
                                        ? Assets.YELLOWCHECK
                                        : documentItem?.document_status ===
                                          "Rejected"
                                        ? Assets.REDCHECK
                                        : documentItem?.document_status ===
                                          "Approved"
                                        ? Assets.GREENCHECK
                                        : ""
                                    }
                                    `}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {documentItem?.file_name && (
                      <p className="pro-mt-1 pro-mb-0 pro-fw-medium pro-text-center">
                        {documentItem?.file_name}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      {modalType === 1 ? (
        //#FIXME MULTIPLE_PDF_VIEWER
        <>
          <ModalLayout
            show={showPdfModal}
            handleClose={closePdfModal}
            backdrop="static"
            propStyle={{
              root: `${Style.pdf_modal} ${
                multipleViewer && lrsData ? Style.multi_one : ""
              } ${multipleViewer && lrsData ? Style.modal_lg : ""}`,
              root_body: Style.modal_body,
            }}
          >
            <PdfCard
              imageData={imageData}
              handleClose={closePdfModal}
              documentStatus={documentStatus}
              itemRejected={itemRejected}
              setItemRejected={setItemRejected}
              rejectReason={rejectReason}
              verification={handleVerification}
              setNote={setNote}
              note={note}
              docId={docId}
              setPdfApprove={setPdfApprove}
              setPdfReject={setPdfReject}
            />
          </ModalLayout>

          {lrsData &&
            (lrsData && !lrsData?.url ? (
              <ModalLayout
                show={showPdfModal}
                handleClose={closePdfModal}
                backdrop="static"
                propStyle={{
                  root: `${Style.pdf_modal} ${
                    multipleViewer && lrsData ? Style.multi_two : ""
                  } ${multipleViewer && lrsData ? Style.modal_lg : ""}`,
                  root_body: Style.modal_body,
                }}
              >
                <PdfCard
                  imageData={lrsData}
                  handleClose={closePdfModal}
                  documentStatus={documentStatus}
                  itemRejected={itemRejected}
                  setItemRejected={setItemRejected}
                  rejectReason={rejectReason}
                  verification={handleVerification}
                  setNote={setNote}
                  note={note}
                  docId={docId}
                  setPdfApprove={setPdfApprove}
                  setPdfReject={setPdfReject}
                  multipleViewer={multipleViewer}
                />
              </ModalLayout>
            ) : (
              <ModalLayout
                show={showImageModal}
                handleClose={closeImageModal}
                backdrop="static"
                propStyle={{
                  root: `${Style.img_modal}${
                    multipleViewer && lrsData ? Style.multi_two : ""
                  } ${multipleViewer && lrsData ? Style.modal_lg : ""}`,
                  root_body: Style.modal_body,
                }}
              >
                <ImageCard
                  data={lrsData}
                  handleClose={closeImageModal}
                  itemRejected={itemRejected}
                  setItemRejected={setItemRejected}
                  documentStatus={documentStatus}
                  rejectReason={rejectReason}
                  verification={handleVerification}
                  setNote={setNote}
                  note={note}
                  docId={docId}
                  setImageApprove={setImageApprove}
                  setImageReject={setImageReject}
                  multipleViewer={multipleViewer}
                />
              </ModalLayout>
            ))}
        </>
      ) : (
        <>
          <ModalLayout
            show={showImageModal}
            handleClose={closeImageModal}
            backdrop="static"
            propStyle={{
              root: `${Style.img_modal} ${
                multipleViewer && lrsData ? Style.multi_one : ""
              } ${multipleViewer && lrsData ? Style.modal_lg : ""}`,
              root_body: Style.modal_body,
            }}
          >
            <ImageCard
              data={imageData}
              handleClose={closeImageModal}
              itemRejected={itemRejected}
              setItemRejected={setItemRejected}
              documentStatus={documentStatus}
              rejectReason={rejectReason}
              verification={handleVerification}
              setNote={setNote}
              note={note}
              docId={docId}
              setImageApprove={setImageApprove}
              setImageReject={setImageReject}
            />
          </ModalLayout>
          {lrsData &&
            (lrsData && !lrsData.url ? (
              <ModalLayout
                show={showPdfModal}
                handleClose={closePdfModal}
                backdrop="static"
                propStyle={{
                  root: `${Style.pdf_modal} ${
                    multipleViewer && lrsData ? Style.multi_two : ""
                  } ${multipleViewer && lrsData ? Style.modal_lg : ""}`,
                  root_body: Style.modal_body,
                }}
              >
                <PdfCard
                  imageData={lrsData}
                  handleClose={closePdfModal}
                  documentStatus={documentStatus}
                  itemRejected={itemRejected}
                  setItemRejected={setItemRejected}
                  rejectReason={rejectReason}
                  verification={handleVerification}
                  setNote={setNote}
                  note={note}
                  docId={docId}
                  setPdfApprove={setPdfApprove}
                  setPdfReject={setPdfReject}
                  multipleViewer={multipleViewer}
                />
              </ModalLayout>
            ) : (
              <ModalLayout
                show={showImageModal}
                handleClose={closeImageModal}
                backdrop="static"
                propStyle={{
                  root: `${Style.img_modal} ${
                    multipleViewer && lrsData ? Style.multi_two : ""
                  } ${multipleViewer && lrsData ? Style.modal_lg : ""}`,
                  root_body: Style.modal_body,
                }}
              >
                <ImageCard
                  data={lrsData}
                  handleClose={closeImageModal}
                  itemRejected={itemRejected}
                  setItemRejected={setItemRejected}
                  documentStatus={documentStatus}
                  rejectReason={rejectReason}
                  verification={handleVerification}
                  setNote={setNote}
                  note={note}
                  docId={docId}
                  setImageApprove={setImageApprove}
                  setImageReject={setImageReject}
                  multipleViewer={multipleViewer}
                />
              </ModalLayout>
            ))}
        </>
      )}

      <ModalLayout
        show={approveAll || rejectAll}
        handleClose={handleCloseConfirmationModal}
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={
              (approveAll && "Are you sure to Confirm all documents ?") ||
              (rejectAll && "Are you sure to Reject all documents ?")
            }
            subTitle={"This action can't be undo "}
            cancelText={"No"}
            cancelAction={handleCloseConfirmationModal}
            submitText={"Yes"}
            a
            submitAction={handleYesAction}
            isRight={true}
          />
        </div>
      </ModalLayout>

      <ModalLayout
        show={pdfApprove || pdfReject || imageApprove || imageReject}
        centered={"true"}
        handleClose={handleCloseDocumentConfirmationModal}
        propStyle={{
          root: Style.confirmation_modal,
        }}
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={
              ((pdfApprove || imageApprove) &&
                "Are you sure to Confirm this document ?") ||
              ((pdfReject || imageReject) &&
                "Are you sure to Reject document ?")
            }
            subTitle={"This action can't be undo "}
            cancelText={"No"}
            cancelAction={handleCloseDocumentConfirmationModal}
            submitText={"Yes"}
            a
            submitAction={handleYesSeperateDocumentAction}
            isRight={true}
          />
        </div>
      </ModalLayout>

      <OffCanvasLayout
        show={uploadModal}
        centered={"true"}
        handleClose={handleCloseUploadModal}
      >
        <UploadForm
          handleCloseUploadModal={handleCloseUploadModal}
          refetch={refetch}
        />
      </OffCanvasLayout>
    </div>
  );
};

export default DocumentDetails;
