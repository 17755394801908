import { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Assets from "../../../../../assets/Assets";
import { useParams } from "react-router-dom";
import { useUploadApplicantDetailDocumentMutation } from "../../../../../store/queries/Report";

const useUploadForm = ({ handleCloseUploadModal, refetch }) => {
  const fileInputRef = useRef(null);
  const { applicant_id } = useParams();
  const [fileInputPreview, setFileInputPreview] = useState("");
  const [uploadApplicantDetailDocument] =
    useUploadApplicantDetailDocumentMutation();

  const documentTypes = [
    {
      label: "LRS Documents",
      key: "lrs",
    },
    {
      label: "University Documents",
      key: "admission_letter",
    },
    {
      label: "Remitter Documents",
      key: "document",
    },
    {
      label: "Student Documents",
      key: "passport",
    },
  ];

  const validation = Yup.object({
    file_type: Yup.object().required(`File Type is Required`),
    file: Yup.mixed().required(`File is Required`),
  });

  const formik = useFormik({
    initialValues: {
      file_type: "",
      file: {},
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      const obj = {
        type: values.file_type.key,
        applicant_id: applicant_id,
      };

      let formData = new FormData();

      if (values?.file?.name) {
        obj.file = values?.file;
      }
      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      uploadApplicantDetailDocument(formData).then((res) => {
        if (res.data?.status_code === 201) {
          handleCloseUploadModal();
          refetch();
          toast.success("File uploaded successfully");
        } else {
          toast.error("Failed to upload file");
        }
      });
    },
  });

  const handleFile = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("file", e?.target?.files?.[0]);

      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onloadend = () => {
        if (file.type.includes("image/")) {
          setFileInputPreview(reader.result);
        } else {
          setFileInputPreview(Assets.PDF_LOGO);
        }
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("file", "The file must be less than 5MB in size.");
    }
  };

  return { formik, documentTypes, fileInputRef, fileInputPreview, handleFile };
};

export default useUploadForm;
