import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { updateConfig } from "../../../../store/slices/User/userSlice";
import { useSelector } from "react-redux";
import useValidations from "../../../../utils/hooks/useValidations";
import { addUser, updateUser } from "../api";
import { toast } from "react-toastify";

const useAddUser = ({ refetch }) => {
  const dispatch = useDispatch();
  const { isEdit, selectedUserDetails, selectedId } = useSelector(
    (state) => state.user
  );
  const { emailRegExp, englishOnlyRegex } = useValidations();

  const validation = Yup.object({
    firstname: Yup.string()
      .matches(englishOnlyRegex, `Enter valid name`)
      .required(`First Name is Required`),
    lastname: Yup.string()
      .matches(englishOnlyRegex, `Enter valid name`)
      .required(`Last Name is Required`),
    email: Yup.string()
      .matches(emailRegExp, `Enter valid email address`)
      .required(`Email is Required`),
  });
  const formik = useFormik({
    initialValues: {
      firstname:
        selectedUserDetails !== "" ? selectedUserDetails?.first_name : "",
      lastname:
        selectedUserDetails !== "" ? selectedUserDetails?.last_name : "",
      email: selectedUserDetails !== "" ? selectedUserDetails?.email : "",
      is_admin:
        selectedUserDetails !== ""
          ? selectedUserDetails?.user_type === "admin"
            ? true
            : false
          : false,
    },

    validationSchema: validation,
    onSubmit: async (values) => {
      let data = {
        firstname: values?.firstname,
        lastname: values?.lastname,
        email: values?.email,
        is_admin: values?.is_admin,
      };

      let updateData = {
        first_name: values?.firstname,
        last_name: values?.lastname,
        email: values?.email,
        is_admin: values?.is_admin,
        id: selectedId ? selectedId : "",
      };

      if (selectedUserDetails === "") {
        addUser(data).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(response?.data?.detail);
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            refetch();
          } else if (response?.status_code === 400) {
            toast.error(response?.error);
          } else {
            toast.error("failed To Add User");
          }
        });
      } else {
        updateUser(updateData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(response?.data?.message);
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
                state.selectedId = "";
                state.isEdit = false;
                state.selectedUserDetails = {};
                state.clearSelectedUser = true;
              })
            );
            refetch();
          } else if (response?.status_code === 400) {
            toast.error(response?.error);
          } else {
            toast.error("Failed To Update User");
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    } else {
      return "";
    }
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };

  return {
    formik,
    isEdit,
    closeModal,
    getFieldError,
  };
};

export default useAddUser;
