import {
  Button,
  IconText,
  Image,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useUploadForm from "./useUploadForm";
import Select from "react-select";
import { limitStrLength } from "../../../../../utils/Functions/table";
import Style from "./uploadForm.module.scss";
import Assets from "../../../../../assets/Assets";

const UploadForm = ({ isStickyFooter, handleCloseUploadModal, refetch }) => {
  const { formik, documentTypes, fileInputRef, fileInputPreview, handleFile } =
    useUploadForm({ handleCloseUploadModal, refetch });
  return (
    <>
      <div className="row">
        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="file_type"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            File Type
          </label>
          <Select
            id="file_type"
            placeholder={"Select"}
            className={`pro-input lg ${
              formik.touched?.file_type && formik.errors?.file_type && "error"
            }`}
            classNamePrefix="pro-input"
            name="file_type"
            options={documentTypes}
            getOptionValue={(option) => option?.key}
            getOptionLabel={(option) => option?.label}
            value={formik?.values?.file_type}
            onBlur={formik.handleBlur("file_type")}
            onChange={(value) => formik.setFieldValue("file_type", value)}
            menuPlacement="auto"
            isClearable
          />
          {formik.touched?.file_type && formik.errors?.file_type && (
            <span className="error-text">{formik.errors?.file_type}</span>
          )}
        </div>

        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            File*
          </label>
          <div className="input-drag">
            <input
              type="file"
              placeholder="placeholder"
              accept=".pdf, .jpg, .jpeg, .png"
              ref={fileInputRef}
              className={`pro-input ${
                formik.touched.file && formik.errors.file && " error"
              }`}
              id="file"
              name="file"
              onBlur={formik.handleBlur("file")}
              onChange={(e) => handleFile(e)}
              disabled={false}
            />
            <span className="input-drag-box">
              <IconText
                title={
                  formik?.values?.file?.name !== undefined
                    ? limitStrLength(formik?.values?.file?.name, 30)
                    : `Drop files to attach or browse`
                }
              />
            </span>
            {formik.touched?.file && formik?.errors?.file && (
              <span className="error-text">{formik?.errors?.file}</span>
            )}
          </div>
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={fileInputPreview ?? Assets.PDF_LOGO}
            alt={`banner - 01`}
          />
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseUploadModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {"Upload"}
        </Button>
      </div>
    </>
  );
};

export default UploadForm;
