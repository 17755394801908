import { Button } from "@wac-ui-dashboard/wac_component_library";
import Style from "./PdfCard.module.scss";
import usePdfCard from "./usePdfCard";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export function PdfCard({
  imageData,
  docId,
  verification,
  handleClose,
  documentStatus,
  setItemRejected,
  itemRejected,
  note,
  setNote,
  rejectReason,
  setPdfApprove,
  setPdfReject,
  multipleViewer,
}) {
  const { documentFrom } = usePdfCard();

  return (
    <div className={`${Style.root} pro-p-2`}>
      <TransformWrapper>
        {({ zoomIn, zoomOut }) => (
          <>
            <div className={Style.action_icon}>
              <button
                className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                onClick={handleClose}
              >
                <span className="material-symbols-outlined">close</span>
              </button>
              <button
                className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                onClick={() => zoomIn()}
              >
                <span className="material-symbols-outlined">zoom_in</span>
              </button>
              <button
                className={`${Style.icon} pro-btn pro-p-0`}
                onClick={() => zoomOut()}
              >
                <span className="material-symbols-outlined">zoom_out</span>
              </button>
            </div>
            <TransformComponent>
              <iframe
                title="PDF Viewer"
                src={imageData || ""}
                height="700px"
                width="100%"
              />
            </TransformComponent>
          </>
        )}
      </TransformWrapper>

      {multipleViewer ? (
        <div
          className={`${Style.buttonless} pro-d-flex pro-mt-2 pro-m-1 pro-justify-end pro-items-center`}
        ></div>
      ) : (
        <div
          className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-end pro-items-center`}
        >
          {rejectReason !== null && (
            <span className="pro-badge badge-grey pro-me-auto">
              {rejectReason}
            </span>
          )}
          {!itemRejected ? (
            documentStatus === "Pending" && documentFrom !== "AI" ? (
              <>
                <Button
                  className="pro-btn lg pro-btn-outline"
                  onClick={() => setPdfReject(true)}
                >
                  Reject
                </Button>
                <Button
                  className="pro-btn lg pro-btn-primary"
                  onClick={() => setPdfApprove(true)}
                >
                  Approve
                </Button>
              </>
            ) : (
              <>
                <div>
                  {documentStatus === "Approved" && documentFrom !== "AI" && (
                    <Button
                      className="pro-btn lg pro-btn-outline"
                      onClick={() => setPdfReject(true)}
                    >
                      Reject
                    </Button>
                  )}

                  {documentStatus === "Rejected" && documentFrom !== "AI" && (
                    <Button
                      className="pro-btn lg pro-btn-primary"
                      onClick={() => setPdfApprove(true)}
                    >
                      Approve
                    </Button>
                  )}
                </div>
                <span
                  className={`pro-badge pro-px-3 pro-py-1 ${
                    documentStatus === "Approved"
                      ? "badge-success-outline"
                      : documentStatus === "Rejected"
                      ? "badge-danger-outline"
                      : "badge-warning-outlined"
                  }`}
                >
                  {documentFrom !== "AI"
                    ? documentStatus
                    : "AI " + documentStatus}
                </span>
              </>
            )
          ) : (
            itemRejected && (
              <>
                <div className="input-wrap">
                  <input
                    placeholder="reason for rejection"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    className="pro-input lg"
                  />
                </div>
                <Button
                  className="pro-btn lg pro-btn-outline"
                  onClick={() => setItemRejected(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="pro-btn lg pro-btn-primary"
                  onClick={() =>
                    verification(docId, false, note) && setItemRejected(false)
                  }
                >
                  Submit
                </Button>
              </>
            )
          )}
        </div>
      )}
    </div>
  );
}
