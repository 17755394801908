import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Report/approvedApplicantSlice";
import {
  useGetApprovedApplicantsQuery,
  useUpdateApprovedApplicantsStatusMutation,
} from "../../../store/queries/Report";
import { useMemo } from "react";
import { toast } from "react-toastify";

const useProcessedApplicants = () => {
  const dispatch = useDispatch();
  const approvedApplicantState = useSelector(
    (state) => state.approvedApplicants
  );

  const [updateApplicantsStatus] = useUpdateApprovedApplicantsStatusMutation();
  const [applicantId, setApplicantId] = useState("");
  const [boardedModal, setBoardedModal] = useState(false);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: approvedApplicantList,
    isLoading,
    isFetching,
    refetch,
  } = useGetApprovedApplicantsQuery({
    search: approvedApplicantState?.search,
    //     fields: approvedApplicantState?.fields,
    page_number: approvedApplicantState?.currentPage,
    paginator_limit: approvedApplicantState?.currentPageSize,
    order_by: {
      field: approvedApplicantState?.sortBy || "",
      order: approvedApplicantState?.sortOrder || "",
    },
    filter: {
      status: approvedApplicantState?.currentFilter,
    },
  });

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
      })
    );
  };

  const handleSort = (label) => {
    if (approvedApplicantState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            approvedApplicantState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      has_boarded: (field, data) => {
        const isChecked = data?.has_boarded;

        return (
          <div className="pro-toggle">
            <div className="pro-toggle-box">
              <input
                id="status"
                name="status"
                type="checkbox"
                checked={isChecked}
                //  onChange={() => changeStatus(data?.utr)}
                onChange={() => handleChangeStatus(data?.id)}
                disabled={data?.has_boarded}
              />
              <span></span>
            </div>
          </div>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows?.["common"]?.(field, data);
  };

  const handleChangeStatus = (id) => {
    setApplicantId(id);
    setBoardedModal(true);
  };

  const handleCloseBoardedModal = () => {
    setBoardedModal(false);
  };

  const handleChangeBoarded = () => {
    const obj = {
      applicant_id: applicantId,
      has_boarded: true,
    };

    updateApplicantsStatus(obj).then((res) => {
      if (res.data?.status_code === 200) {
        toast.success(
          res?.data?.message ?? "Student status updated successfully"
        );
        setApplicantId("");
        setBoardedModal(false);
        refetch();
      } else {
        toast.error("Failed to update student status");
        setBoardedModal(false);
        setApplicantId("");
      }
    });
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const filters = useMemo(() => {
    if (approvedApplicantList?.data?.filter_data) {
      const buttonGroups = approvedApplicantList?.data?.filter_data?.filter(
        (d) => !d?.type
      );
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, approvedApplicantList]);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  return {
    approvedApplicantList,
    approvedApplicantState,
    isLoading,
    paginationOptions,
    isFetching,
    filters,
    activeFilter: approvedApplicantState.currentFilter,
    boardedModal,
    handleCloseBoardedModal,
    handleChangeBoarded,
    handleFilter,
    handlePagination,
    handlePageSize,
    getRow,
    handleSort,
    handleSearch,
    handleClearClick,
  };
};

export default useProcessedApplicants;
