import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const useReport = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const drawerMenu = [
    {
      title: "Booking Report",
      link: "/report/booking-report",
      icon: <span className="material-symbols-outlined">edit_square</span>,
      active: checkIfActiveRoute("/report/booking-report", true),
    },
    {
      title: "Transaction",
      link: "/report/transaction",
      icon: (
        <span className="material-symbols-outlined">currency_exchange</span>
      ),
      active: checkIfActiveRoute("/report/transaction", true),
    },
    {
      title: "Applicants",
      link: "/report/applicants",
      icon: <span className="material-symbols-outlined">menu_book</span>,
      active: checkIfActiveRoute("/report/applicants", true),
    },

    {
      title: "Pending Applicants",
      link: "/report/pending-applicants",
      icon: <span class="material-symbols-outlined">other_admission</span>,
      active: checkIfActiveRoute("/report/pending-applicants", true),
    },
    {
      title: "Refunds",
      link: "/report/refunds",
      icon: <span class="material-symbols-outlined">receipt_long</span>,
      active: checkIfActiveRoute("/report/refunds", true),
    },
    {
      title: "Processed Applicants",
      link: "/report/processed-applicants",
      icon: <span class="material-symbols-outlined">how_to_reg</span>,
      active: checkIfActiveRoute("/report/processed-applicants", true),
    },
    {
      title: "Excess Credited",
      link: "/report/excess-credited",
      icon: <span class="material-symbols-outlined">local_atm</span>,
      active: checkIfActiveRoute("/report/excess-credited", true),
    },
  ];

  return { drawerMenu: drawerMenu };
};

export default useReport;
