import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetApplicationDetailDocumentQuery,
  useVerifyAllDocumentsMutation,
} from "../../../../store/queries/Report";
import { getImage, verifyDocument } from "./api";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Report/Applicant/applicantsDocumentSlice";

const useDocumentDetails = () => {
  const dispatch = useDispatch();
  const { applicant_id } = useParams();
  const { applicantDetails } = useSelector((state) => state.applicants);
  const { modalType, approveAll, rejectAll, showImageModal, showPdfModal } =
    useSelector((state) => state.applicantDocument);
  const [verifyAllDocuments] = useVerifyAllDocumentsMutation();
  const [imageData, setImageData] = useState("");
  const [lrsData, setLrsData] = useState("");
  const [documentStatus, setDocumentStatus] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [itemRejected, setItemRejected] = useState(false);
  const [multipleViewer, setMultipleViewer] = useState(false);
  const [note, setNote] = useState("");
  const [docId, setDocId] = useState("");
  const [docFrom, setDocFrom] = useState("");

  //states to open confirmation modals while clicking approve and rejected
  const [imageApprove, setImageApprove] = useState(false);
  const [imageReject, setImageReject] = useState(false);
  const [pdfApprove, setPdfApprove] = useState(false);
  const [pdfReject, setPdfReject] = useState(false);

  //states to upload documents manually
  const [uploadModal, setUploadModal] = useState(false);
  const {
    data: DocumentData = {},
    isFetching,
    refetch,
  } = useGetApplicationDetailDocumentQuery({
    applicant_id: applicant_id,
  });

  const handleViewImage = async (item, mainItem) => {
    if (
      mainItem.title === "Remitter Documents" ||
      mainItem.title === "Student Documents"
    ) {
      setMultipleViewer(true);
      const lrsItem = DocumentData.data?.filter(
        (item) => item.title === "LRS Documents"
      )?.[0]?.documents?.[0];
      if (lrsItem?.file_type === "PDF document") {
        // setShowPdfModal(true);
        dispatch(
          updateConfig((state) => {
            state.showPdfModal = true;
          })
        );
        getImage(lrsItem?.url).then((res) => {
          if (res?.status === 200) {
            // Create a Blob from the PDF content
            const pdfBlob = new Blob([res?.data], { type: "application/pdf" });
            const data = URL.createObjectURL(pdfBlob);
            // Create a Blob URL
            setLrsData(data);
          } else if (res?.status === 404) {
            toast.error(res.statusText ?? "file does not exist");
          } else {
            toast.error(res.statusText ?? "An error occured");
          }
        });
      } else {
        dispatch(
          updateConfig((state) => {
            state.showImageModal = true;
          })
        );
        setLrsData(lrsItem);
      }
    }

    dispatch(
      updateConfig((state) => {
        state.documentFrom = item?.document_from;
      })
    );
    setDocId(item?.document_id);
    setDocumentStatus(item?.document_status);
    setRejectReason(item?.rejection_reason);
    setDocFrom(item?.document_from);
    if (item?.file_type === "PDF document") {
      dispatch(
        updateConfig((state) => {
          state.modalType = 1;
        })
      );
      getImage(item?.url).then((res) => {
        if (res?.status === 200) {
          // Create a Blob from the PDF content
          const pdfBlob = new Blob([res?.data], { type: "application/pdf" });
          const data = URL.createObjectURL(pdfBlob);
          // Create a Blob URL
          setImageData(data);
        } else if (res?.status === 404) {
          toast.error(res.statusText ?? "file does not exist");
        } else {
          toast.error(res.statusText ?? "An error occured");
        }
      });
      dispatch(
        updateConfig((state) => {
          state.showPdfModal = true;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.modalType = 2;
          state.showImageModal = true;
        })
      );

      setImageData(item?.url);
    }
  };

  const closePdfModal = () => {
    setImageData("");
    setDocumentStatus(false);
    setRejectReason("");
    setItemRejected(false);
    setImageData("");
    setLrsData("");
    setMultipleViewer(false);
    dispatch(
      updateConfig((state) => {
        state.showPdfModal = false;
        state.showImageModal = false;
        state.documentForm = "";
      })
    );
  };

  const closeImageModal = () => {
    setImageData("");
    setDocumentStatus(false);
    setRejectReason("");
    setItemRejected(false);
    setImageData("");
    setLrsData("");
    setMultipleViewer(false);
    dispatch(
      updateConfig((state) => {
        state.showPdfModal = false;
        state.showImageModal = false;
        state.documentForm = "";
      })
    );
  };
  const handleVerification = (id, status, note) => {
    let formData = {
      document_id: id,
      is_approve: status,
      rejection_reason: note,
      document_from: docFrom,
    };
    verifyDocument(formData)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          toast.success(result?.data?.message);

          setImageData("");
          setItemRejected(false);
          setDocId("");
          setDocFrom("");
          dispatch(
            updateConfig((state) => {
              state.documentFrom = "";
              state.showPdfModal = false;
              state.showImageModal = false;
            })
          );
          refetch();
        } else {
          toast.error(result?.data?.message);
          setImageData("");
          setItemRejected(false);
          setDocId("");
          setDocFrom("");
          dispatch(
            updateConfig((state) => {
              state.documentFrom = "";
              state.showPdfModal = false;
              state.showImageModal = false;
            })
          );
        }
      })
      .catch((err) => {
        toast.error("Failed to verify document");
      });
  };

  const handleApproveAllClick = () => {
    dispatch(updateConfig((state) => (state.approveAll = true)));
  };

  const handleRejectAllClick = () => {
    dispatch(updateConfig((state) => (state.rejectAll = true)));
  };

  //closing confirmation modal of confirm  all
  const handleCloseConfirmationModal = () => {
    dispatch(
      updateConfig((state) => {
        state.approveAll = false;
        state.rejectAll = false;
      })
    );
  };

  //closing confirmation modal of seperate documents
  const handleCloseDocumentConfirmationModal = () => {
    setPdfApprove(false);
    setImageApprove(false);
    setPdfReject(false);
    setImageReject(false);
  };

  //yes action of document all verify/reject button
  const handleYesAction = () => {
    dispatch(
      updateConfig((state) => {
        state.approveAll = false;
        state.rejectAll = false;
      })
    );
    const data = {
      is_approved: approveAll ? true : false,
      applicant_id: +applicantDetails?.applicant_id,
    };
    verifyAllDocuments(data).then((res) => {
      if (res?.data?.status_code === 200) {
        refetch();
        toast.success("All document status updated successfully");
      } else if (res?.error?.data?.status_code === 400) {
        toast.error(
          res?.error?.data?.message ??
            "Failed to update status of the documents"
        );
      } else {
        toast.error("Failed to update status of the documents");
      }
    });
  };

  //yes action of seperate document verify /reject parts
  const handleYesSeperateDocumentAction = () => {
    //intially we want to close the confirmation modal
    handleCloseDocumentConfirmationModal();

    if (itemRejected === true) {
      handleVerification(docId, false, note);
    } else {
      if (pdfApprove || imageApprove) {
        handleVerification(docId, true, note);
      } else {
        setItemRejected(true);
        setNote("");
      }
    }
  };

  const handleUploadClick = () => {
    setUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setUploadModal(false);
  };

  return {
    DocumentData,
    isFetching,
    showImageModal,
    imageData,
    showPdfModal,
    modalType,
    itemRejected,
    documentStatus,
    rejectReason,
    note,
    docId,
    approveAll,
    rejectAll,
    imageApprove,
    imageReject,
    pdfApprove,
    pdfReject,
    uploadModal,
    multipleViewer,
    lrsData,
    refetch,
    handleCloseUploadModal,
    handleUploadClick,
    setImageApprove,
    setImageReject,
    setPdfApprove,
    setPdfReject,
    handleRejectAllClick,
    handleYesAction,
    handleCloseConfirmationModal,
    handleYesSeperateDocumentAction,
    handleCloseDocumentConfirmationModal,
    handleApproveAllClick,
    handleVerification,
    setNote,
    setItemRejected,
    closePdfModal,
    closeImageModal,
    handleViewImage,
  };
};

export default useDocumentDetails;
