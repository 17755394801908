import React from "react";
import {
  Header,
  NavGroup,
  ProfileSideBar,
  MultiColumnTable,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { Link, Outlet } from "react-router-dom";
import Style from "../../../../pages/Layout/CommonLayout/commonLayout.module.scss";
import useApplicantDetailLayout from "./useApplicantDetailLayout";
import ProtectRoute from "../../../../utils/components/ProtectRoute/protectRoute";
import HeaderActions from "../../../../pages/Layout/CommonLayout/HeaderActions";
import useCommonLayout from "../../../../pages/Layout/CommonLayout/useCommonLayout";
import profileStyle from "./applicantDetailLayout.module.scss";
import ProfileLayoutSidebarShimmer from "../../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import Assets from "../../../../assets/Assets";
import BasicDetailsForm from "./BasicDetailsForm";

const ApplicantDetialLayout = () => {
  const {
    isFetching,
    profileData,
    applicantDetails,
    navigation,
    showDetailseditModal,
    refetch,
    closeDetailsEditModal,
    handleEditClick,
    // activeProfile,
  } = useApplicantDetailLayout();
  const { navigations, globalState } = useCommonLayout();

  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.LOGO
            : Assets.LOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />
      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(profileData?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div
                    className={`pro-pt-5 pro-d-flex pro-flex-column pro-h-100`}
                  >
                    <div className="pro-px-4">
                      <div className={`pro-border-bottom pro-pb-3 `}>
                        <Link
                          to={`/report/applicants`}
                          className="pro-back-btn"
                        >
                          <span className="material-symbols-outlined">
                            {" "}
                            keyboard_arrow_left{" "}
                          </span>
                          {`Back`}
                        </Link>
                        <div className="pro-d-flex pro-justify-between pro-items-center pro-mt-3">
                          <h4 className={`h4 pro-ttl pro-mb-0 `}>
                            {profileData?.result?.virtural_account_number}
                          </h4>
                          <span className=" pro-badge badge-primary pro-ms-3">
                            {profileData?.result?.payment_status}
                          </span>
                        </div>
                      </div>
                      <div className={`${profileStyle.applicant_items_wrap}`}>
                        {/* <div className="pro-d-flex pro-justify-end">
                          <span
                            className={`material-symbols-outlined x3`}
                            onClick={() => handleEditClick()}
                          >
                            edit_square
                          </span>
                        </div> */}
                        <MultiColumnTable
                          title={"Applicant Details"}
                          data={applicantDetails}
                          handleEdit={() => handleEditClick()}
                          // editIcon={
                          //   <span className={`material-symbols-outlined x3`}>
                          //     edit_square
                          //   </span>
                          // }
                          extraClassNames={profileStyle.table}
                        />
                      </div>
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>

          <OffCanvasLayout
            show={showDetailseditModal}
            handleClose={closeDetailsEditModal}
            title={"Edit Basic Details"}
            closeIcon={<span className="material-symbols-outlined">close</span>}
            backdrop="static"
          >
            <BasicDetailsForm
              profileData={profileData}
              closeDetailsEditModal={closeDetailsEditModal}
              refetch={refetch}
            />
          </OffCanvasLayout>

          <div className={`${profileStyle.main_container} pro-pt-4 pro-ps-5`}>
            <NavGroup navigations={navigation} type={"type-2"} Link={Link} />
            <Outlet />
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default ApplicantDetialLayout;
