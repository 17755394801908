import { createApi } from "@reduxjs/toolkit/query/react";
import { getAdminAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAdminAxiosInstance();

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("task_type")}&${getParams(
          "user_type_sort"
        )}${getParams("start_date")}${getParams("search")}&${getParams(
          "end_date"
        )}&${getParams("sort_by")}&sort=${
          params?.sort_order || "desc"
        }&paginator_limit=${params?.paginator_limit || "10"}&page_number=${
          params?.page_number || 1
        }&applicant_id=${params?.applicant_id || ""}&order_by=${
          params?.order_by
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const report = createApi({
  reducerPath: "reportApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Report",
    "Transaction",
    "Applicant",
    "Applicant-Detail",
    "Applicant-Detail-Transaction",
    "Applicant-Detail-Document",
    "Applicant-Detail-Activity",
    "Pending-Applicants",
    "Refund-Transaction",
    "Approved-Applicants",
    "Excess-Credited",
  ],
  endpoints: (builder) => ({
    //booking report section starts
    getBookingReportData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "api-booking-report-list",
      }),
      providesTags: ["Report"],
    }),
    updateBookingReportTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `api-booking-report-list`,
      }),
      invalidatesTags: ["Report"],
    }),
    //booking report section ends
    //transaction section starts
    getTransactionData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "api-payment-data-list",
      }),
      providesTags: ["Transaction"],
    }),
    updateTransactionTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Transaction"],
    }),
    updateTransactionStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `api/refund-transaction`,
      }),

      invalidatesTags: ["Transaction"],
    }),
    //transaction section ends
    //applicants section starts
    getApplicantData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "api-payment-proceeded-list",
      }),
      providesTags: ["Applicant"],
    }),
    updateApplicantTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "",
      }),
      invalidatesTags: ["Applicant"],
    }),
    //applicant -detail starts here
    getApplicantDetail: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "api-applicant-details",
      }),
      providesTags: ["Applicant-Detail"],
    }),
    updateApplicantDetails: builder.mutation({
      query: (body) => ({
        method: "put",
        body,
        endpoint: "api-applicant-details",
      }),
      invalidatesTags: ["Applicant-Detail"],
    }),
    //applicant -detail transaction starts here
    getApplicantDetailTransaction: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "api-payment-data-list",
      }),
      providesTags: ["Applicant-Detail-Transaction"],
    }),
    manuallyApprovePayments: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "payment-manually-aprrove-or-reject",
      }),
      invalidatesTags: ["Applicant-Detail"],
    }),
    //applicant -detail transaction endes here

    //applicant -detail document starts here
    getApplicationDetailDocument: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "document-details",
      }),
      providesTags: ["Applicant-Detail-Document"],
    }),
    uploadApplicantDetailDocument: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "applicant-document-add",
      }),
      invalidatesTags: ["Applicant-Detail-Document"],
    }),
    //applicant -detail document ends here
    //applicant-detail-activity starts here
    getApplicationDetailActivity: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "activity-log-details",
      }),
      providesTags: ["Applicant-Detail-Activity"],
    }),
    //applicant-detail-activity ends here

    //applicants details ends here
    //applicants section ends
    //pending applicant starts here

    getPendingApplicant: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "applicant-pending-list",
      }),
      providesTags: ["Pending-Applicants"],
    }),
    //pending applicant ends here
    //refund transaction starts here
    getRefundTransaction: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "api/refund-transaction-list",
      }),
      providesTags: ["Refund-Transaction"],
    }),
    //refund transaction ends here

    //approved/boarded applicants starts here
    getApprovedApplicants: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "approved-applicant-list",
      }),
      providesTags: ["Approved-Applicants"],
    }),
    updateApprovedApplicantsStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "change-approved-to-boarded",
      }),
      invalidatesTags: ["Approved-Applicants"],
    }),
    //approved/boarded applicants ends here
    //excess credited starts here
    getExcessCredited: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "api/excess-amount-list",
      }),
      providesTags: ["Excess-Credited"],
    }),
    //excess credited ends here

    verifyAllDocuments: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "manually-aprrove-or-reject-all-documents",
      }),
    }),
  }),
});

export const {
  useGetBookingReportDataQuery,
  useGetApplicantDetailQuery,
  useUpdateBookingReportTableHeadDataMutation,
  useGetTransactionDataQuery,
  useUpdateTransactionTableHeadDataMutation,
  useGetApplicantDataQuery,
  useUpdateApplicantTableHeadDataMutation,
  useGetApplicantDetailTransactionQuery,
  useGetApplicationDetailDocumentQuery,
  useUpdateApplicantDetailsMutation,
  useVerifyAllDocumentsMutation,
  useGetApplicationDetailActivityQuery,
  useGetPendingApplicantQuery,
  useManuallyApprovePaymentsMutation,
  useUploadApplicantDetailDocumentMutation,
  useUpdateTransactionStatusMutation,
  useGetRefundTransactionQuery,
  useGetApprovedApplicantsQuery,
  useUpdateApprovedApplicantsStatusMutation,
  useGetExcessCreditedQuery,
} = report;
